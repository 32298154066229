export default [
    {
        path: '/scrivania',
        name: 'rubisco-dashboard',
        //component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
        component: () => import('@/views/scrivania/v1/Ecommerce.vue'),
        meta: {
            pageTitle: 'Scrivania',
            action: 'read',
            resource: 'ACL',
        },
    },
]

export default [
  {
    path: '/catalogo/articoli',
    name: 'catalogo-articoli',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoli.vue'),
    meta: {
      pageTitle: 'I tuoi Prodotti',
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/articolidisponibili',
    name: 'catalogo-articoli-tutti',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoliTutti.vue'),
    meta: {
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/articolidisponibili/cat/:liv1',
    name: 'catalogo-articoli-tutti-categoria',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoliTuttiCategoria.vue'),
    meta: {
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/articolidisponibili/cat/:liv1/:liv2',
    name: 'catalogo-articoli-tutti-categoria-lv2',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoliTuttiCategoria-lv2.vue'),
    meta: {
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/articolidisponibili/cat/:liv1/:liv2/:liv3',
    name: 'catalogo-articoli-tutti-categoria-lv3',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoliTuttiCategoria-lv3.vue'),
    meta: {
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/generale',
    name: 'catalogo-articoli-generale',
    component: () => import('@/views/rubisco-catalogo-prodotti/listaArticoliTuttiGenerale.vue'),
    meta: {
      action: 'read',
      resource: 'ACL'
    },
  },
  {
    path: '/catalogo/carrello',
    name: 'catalogo-carrello',
    component: () => import('@/views/rubisco-catalogo-prodotti/carrello.vue'),
    meta: {
      pageTitle: 'Articoli nell\'Ordine',
      action: 'read',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'I tuoi Prodotti',
          to: 'articoli',
        },
        {
          text: 'Carrello',
          active: true,
        },
      ],
    },
  },
  {
    path: '/catalogo/Contatti',
    name: 'catalogo-contatti',
    component: () => import('@/views/ui/page-layouts/LayoutBlank.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Contatti',
      action: 'read',
      resource: 'ACL',
    },
  },
]

export default [
    {
      path: '/crm/aziende',
      name: 'crm-aziende',
      component: () => import('@/views/rubisco-crm/listaAziende.vue'),
      meta: {
        pageTitle: 'CRM Admin - Lista Aziende',
      },
    },
    {
      path: '/crm/scheda_azienda/:id_azienda',
      name: 'crm-azienda-scheda',
      component: () => import('@/views/rubisco-crm/schedaAzienda.vue'),
      meta: {
        pageTitle: 'CRM Admin - Scheda Azienda',
        breadcrumb: [
          {
            text: 'Lista Aziende',
            to: '../aziende',
          },
        ],
      },
    },
    {
      path: '/crm/utenti_azienda/:id_azienda',
      name: 'crm-azienda-utenti',
      component: () => import('@/views/rubisco-crm/UtentiAzienda.vue'),
      meta: {
        pageTitle: 'CRM Admin - Utenti in questa Azienda',
        breadcrumb: [
          {
            text: 'Lista Aziende',
            to: '../aziende',
          },
        ],
      },
    },
    {
      path: '/crm/utenti_azienda_nuovo/:id_azienda',
      name: 'crm-azienda-utenti-nuovo',
      component: () => import('@/views/rubisco-crm/UtentiAziendaNuovo.vue'),
      meta: {
        pageTitle: 'CRM Admin - Nuovo Utente',
        breadcrumb: [
          {
            text: 'Lista Aziende',
            to: '../aziende',
          },
          {
            text: 'Lista Utenti Azienda',
            to: {name: 'crm-azienda-utenti' },
          },
        ],
      },
    },
    {
      path: '/crm/utenti_azienda_modifica/:id_azienda/:id_utente',
      name: 'crm-azienda-utenti-modifica',
      component: () => import('@/views/rubisco-crm/UtentiAziendaModifica.vue'),
      meta: {
        pageTitle: 'CRM Admin - Modifica Utente',
        breadcrumb: [
          {
            text: 'Lista Aziende',
            to: '../aziende',
          },
          {
            text: 'Lista Utenti Azienda',
            to: {name: 'crm-azienda-utenti' },
          },
        ],
      },
    },
    {
      path: '/crm/leads',
      name: 'crm-leads-in-corso',
      component: () => import('@/views/rubisco-crm/listaLead.vue'),
      meta: {
        pageTitle: 'CRM Admin - Leads in Corso',
      },
    },
    {
      path: '/crm/leads/in-attesa',
      name: 'crm-leads-in-attesa',
      component: () => import('@/views/rubisco-crm/listaLead-in-attesa.vue'),
      meta: {
        pageTitle: 'CRM Admin - Richieste in attesa',
      },
    },
    {
      path: '/crm/leads/concluse',
      name: 'crm-leads-conlcuse',
      component: () => import('@/views/rubisco-crm/listaLead-conclusi.vue'),
      meta: {
        pageTitle: 'CRM Admin - Leads Conclusi',
      },
    },
    {
      path: '/crm/leads/nel-cestino',
      name: 'crm-leads-nel-cestino',
      component: () => import('@/views/rubisco-crm/listaLead-cestino.vue'),
      meta: {
        pageTitle: 'CRM Admin - Leads nel cestino',
      },
    },
    {
      path: '/crm/leadinfo/:id_lead/:id_azienda',
      name: 'crm-lead-dettaglio',
      component: () => import('@/views/rubisco-crm/leadInfoAzienda.vue'),
      meta: {
        pageTitle: 'CRM Admin - Riepilogo Richiesta',
        breadcrumb: [
          {
            text: 'Lista Aziende',
            to: '../../aziende',
          },
          {
            text: 'Richieste in Corso',
            to: '../../leads',
          },
        ],
      },
    },
    {
      path: '/crm/ordini',
      name: 'crm-ordini',
      component: () => import('@/views/rubisco-crm/listaOrdini.vue'),
      meta: {
        pageTitle: 'CRM Admin - Ordini Effettuati',
        breadcrumb: [
          {
            text: 'Lista Aziende',
            to: '../../aziende',
          },
        ],
      },
    },
    {
      path: '/crm/ordineinfo/:id/:numero_ordine',
      name: 'crm-ordine-info',
      component: () => import('@/views/rubisco-crm/ordineInfoAzienda.vue'),
      meta: {
        pageTitle: 'CRM Admin - Riepilogo Ordine',
        breadcrumb: [
          {
            text: 'Lista Aziende',
            to: '../../aziende',
          }
        ],
      },
    },
]

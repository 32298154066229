import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  //baseURL: 'http://192.168.1.7/rubisco-6-vue/api/',
  baseURL: 'https://areaclienti.biomedicalgroup.it/api/',
  //timeout: 2000,
  //headers: {'Content-type': 'application/json', 'X-rubiscovue-Header': 'rubiscovue-1-0'} 
  headers: {'X-rubiscovue-Header': 'rubiscovue-1-0'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
